import React, {MouseEventHandler} from "react";
import {Button, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {AwsAuthenticatorComponent} from "./AwsAuthenticator";
import {MDBBtn} from "mdb-react-ui-kit";

export interface TopNavigationUserData {
    displayName: String
    logOutAction: MouseEventHandler
}

interface TopNavigationProps {
    userData?: TopNavigationUserData
    testSetup?: boolean

}

export const TopNavigation = ({userData, testSetup}: TopNavigationProps) => {
    let buttonComponent
    if (userData === undefined) {
        buttonComponent = (<LoginButton/>)
    } else {
        buttonComponent = (
            <LoggedView displayName={userData.displayName} logOutAction={userData.logOutAction}/>
        )
    }
    let authComponent
    if (!testSetup) {
        authComponent = (<div style={{display: "none"}}>
            <AwsAuthenticatorComponent/>
        </div>)
    } else {
        authComponent = (<></>)

    }

    return (
        <Row role={"row"} className={"p-1"}>
            {authComponent}
            <div role={"button-wrapper"} className={"d-flex justify-content-end TopNavigation"}>
                {buttonComponent}
            </div>
        </Row>)
}

const LoginButton = () => {
    return (
        <Link to={"/user-access"}>
            <MDBBtn color={"white"}>Zaloguj się</MDBBtn>
        </Link>
    )
}

const LoggedView = ({displayName, logOutAction}: TopNavigationUserData) => {
    return (
        <div>
            <Link to={"/user-access"}>
                <MDBBtn role={"user-badge"} color={"secondary"} className={"me-2"}>{displayName} (konto)</MDBBtn>
            </Link>
            <Link to={"/"}>
                <MDBBtn color={"white"} onClick={logOutAction}>Wyloguj</MDBBtn>
            </Link>
        </div>
    )
}