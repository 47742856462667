import {MDBBtn, MDBContainer, MDBInput, MDBRow} from "mdb-react-ui-kit";
import {useTranslation} from "react-i18next";
import {useState} from "react";


export const C5NUserRetrievalPage = () => {
    const {t} = useTranslation();

    const [requestSent, setRequestSent] = useState(false)
    const onSubmitFunction = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (requestSent) {
            return
        }
        setRequestSent(true);
        const email = (document.getElementById("c5n-retrieve-email-address") as HTMLInputElement).value
        let apiEndpoint: string | undefined = process.env.REACT_APP_API_ENDPOINT
        let response: Response = await fetch(apiEndpoint + "signed_contents", {
            method: "POST",
            body: JSON.stringify({
                email_address: email,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });

    }
    return (
        <MDBContainer>
            {!requestSent && <MDBRow className={"pt-5"}>
                <h1 className={"display-3 text-center"}>{t('retrieve-c5ns-title')}</h1>
                <p>{t('retrieve-c5ns-explanation')}</p>
                <form onSubmit={onSubmitFunction}>
                    <MDBInput className='mb-4' size={"lg"} type='email' id='c5n-retrieve-email-address'
                              label={t('email-address-label')}/>
                    <MDBBtn className='mb-4' type='submit' block size={"lg"}>
                        {t('retrieve-c5ns-button-label')}
                    </MDBBtn>
                </form>
            </MDBRow>}
            {requestSent && <MDBRow className={"pt-5"}>
                <h1 className={"display-3 text-center"}>{t('c5n-after-retrieve-explanation')}</h1>
            </MDBRow>}
        </MDBContainer>
    )
}