import React, {useState} from "react";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBIcon,
    MDBRow,
    MDBTabsContent,
    MDBTabsPane,
    MDBTypography
} from "mdb-react-ui-kit";
import * as _ from "lodash";
import {C5NProduct} from "../c5n/C5NSellingPage";
import {useTranslation} from "react-i18next";


export interface PricingTableArguments {
    elements: C5NProduct[]
}

export type BillingCycle = "YEARLY" | "MONTHLY"

export function billingCycleToName(bc: BillingCycle): string {
    if (bc === "YEARLY") {
        return "Co rok"
    } else if (bc === "MONTHLY") {
        return "Co miesiąc"
    } else {
        return "Jednorazowo"
    }
}

export function billingCycleToNamePostName(bc: BillingCycle, t: (a: string) => string): string {
    if (bc === "YEARLY") {
        return t("c5n-selling-page-year")
    } else if (bc === "MONTHLY") {
        return t("c5n-selling-page-month")
    } else {
        return t("c5n-selling-page-once")
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }

        interface IntrinsicElements {
            'stripe-buy-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}