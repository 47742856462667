import {Params} from "react-router-dom";
import React, {useEffect, useState} from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./NewsletterLanding.css"
import "bootstrap/dist/css/bootstrap.min.css"
import {MDBContainer, MDBIcon, MDBSpinner} from "mdb-react-ui-kit";
import {TermsOfService} from "./TermsOfService";
import ReactGA from "react-ga4";
import {billingCycleToNamePostName} from "./PricingTable";
import {C5NProduct, C5NSellingResponse} from "../c5n/C5NSellingPage";
import {Affix, Avatar, Badge, Button, Card, Col, Divider, Image, Layout, Modal, Row} from "antd";
import {chain} from "lodash";
import {C5NTermsOfServiceBuyer} from "../c5n/C5NTermsOfServiceBuyer";
import FeedbackButton from "./FeedbackButton";
import {useTranslation} from "react-i18next";


interface NewsletterLandingParams {
    routerParams: () => Readonly<Params>
}

interface NewsletterResponse {
    splash_text: string | undefined
    avatar_url: string | undefined
    pretty_name: string | undefined
    video_url: string | undefined
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }

        interface IntrinsicElements {
            'stripe-buy-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

async function getNewsletterLandingData(newsletterName: string): Promise<NewsletterResponse> {
    let apiEndpoint: string | undefined = process.env.REACT_APP_API_ENDPOINT
    let response = await fetch(apiEndpoint + 'newsletter?slug=' + newsletterName).catch(_ => undefined)
    if (response === undefined || !response.ok) {
        throw new Error("Couldn't fetch newsletter data")
    }
    let json = await response.json()
    return json as NewsletterResponse
}

const {Content} = Layout;

export function NewsletterLanding({routerParams}: NewsletterLandingParams) {
    const {t} = useTranslation()
    const [newsletterParams, setNewsletterParams] = useState(undefined as NewsletterResponse | undefined);
    let {newsletterName} = routerParams()

    //
    useEffect(() => {
        let f = async () => {
            if (newsletterName === undefined) {
                return;
            }
            let params = await getNewsletterLandingData(newsletterName)
            if (params !== undefined) {
                setNewsletterParams(params);
            }
        }

        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual
        if (newsletterParams === undefined) {
            f();
        }
    }, [newsletterName, newsletterParams]);
    useEffect(() => {
        if (newsletterName !== undefined) {
            ReactGA.send(
                {
                    hitType: "pageview",
                    page: "newsletter_landing_" + newsletterName,
                });
        }
    }, [newsletterName]);
    let apiEndpoint: string | undefined = process.env.REACT_APP_API_ENDPOINT
    let [storedC5nResponse, setStoredC5nResponse] = useState(undefined as C5NSellingResponse | undefined)
    useEffect(() => {
        let f = async () => {
            let response = await fetch(apiEndpoint + 'c5n_selling?slug=' + newsletterName).catch(_ => undefined)
            if (response !== undefined && response.ok && response.body !== null) {
                setStoredC5nResponse(await response.json() as C5NSellingResponse)
            }
        }
        f()
    }, [newsletterName]);

    if (newsletterParams === undefined && storedC5nResponse === undefined) {
        return (
            <MDBContainer style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <div className={"text-center display-1"}>
                    <MDBSpinner/>
                </div>
            </MDBContainer>
        );
    }


    const singlePurchaseProducts = storedC5nResponse ? storedC5nResponse.products.filter((prod) => prod.payment_type === "ONE_TIME") : []
    const subscriptionProducts = storedC5nResponse ? storedC5nResponse.products.filter((prod) => prod.payment_type !== "ONE_TIME") : []
    let groupedSubbedProducts = chain(subscriptionProducts).groupBy('product_name').value()
    const cardGridStyle = {width: '100%',}
    return (
        <Layout>
            <FeedbackButton/>
            <Content style={{minHeight: "100vh"}}>
                <Affix>
                    <Row className={"mt-1"} gutter={[16, 16]} justify={"center"}>
                        {subscriptionProducts.length > 0 && <Button className={"mx-1"}
                                                                    onClick={() => {
                                                                        document.querySelector('#subscriptions')?.scrollIntoView()
                                                                    }}
                        >
                            {t("newsletter-landing-subscriptions-top-row")}
                        </Button>}
                        {singlePurchaseProducts.length > 0 && <>
                            <Button className={"mx-1"} onClick={() => {
                                document.querySelector('#virtualproducts')?.scrollIntoView()
                            }}>
                                {t("newsletter-landing-products-top-row")}
                            </Button>
                            <Button className={"mx-1"} type={"primary"} onClick={() => {
                                const newest = document.querySelector('#newest');
                                newest?.scrollIntoView();
                                if (newest) {
                                    document.getElementById('newest')?.classList.add('pulsing-box');
                                    setTimeout(() => {
                                            document.getElementById('newest')?.classList.remove('pulsing-box');
                                        }
                                        , 3000);
                                }

                            }}>
                                {t("newsletter-landing-newset-product-top-row")}
                            </Button>
                        </>}

                    </Row>
                </Affix>
                <Row className={"mt-1"} gutter={[16, 16]} justify={"center"}>
                    <Col lg={12}>
                        <div className={"display-3 text-center"}>{newsletterParams?.avatar_url && <Avatar size={"large"}
                                                                         src={newsletterParams?.avatar_url}/>} {newsletterParams?.pretty_name}
                        </div>
                        <div className={"display-6 m-2 text-center"} style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            fontSize: "1.5em"

                        }}>{newsletterParams?.splash_text}</div>
                    </Col>
                </Row>
                {subscriptionProducts.length > 0 &&
                    <><Divider><span id={"subscriptions"}>{t("newsletter-landing-subscriptions")}</span></Divider>
                        <Row gutter={[16, 16]} className={"mx-1"} style={{display: 'flex'}}>
                            {groupedSubbedProducts && Object.keys(groupedSubbedProducts).map((key) => {
                                let yearlyProduct: C5NProduct | undefined = groupedSubbedProducts[key].find((prod) => prod.payment_type === "YEARLY")
                                let monthlyProduct: C5NProduct | undefined = groupedSubbedProducts[key].find((prod) => prod.payment_type === "MONTHLY")
                                let someProduct = yearlyProduct || monthlyProduct
                                if (!someProduct) {
                                    return null
                                }
                                let actions: React.ReactNode[] = []
                                if (monthlyProduct) {
                                    actions.push(
                                        <a href={monthlyProduct.stripe_link_url} target={"_blank"} rel={"noreferrer"}>
                                            <Button type={"primary"}>
                                                {(monthlyProduct.price) / 100} {monthlyProduct.currency} / {billingCycleToNamePostName("MONTHLY", t)}
                                            </Button>
                                        </a>
                                    )
                                }
                                if (yearlyProduct) {
                                    actions.push(
                                        <a href={yearlyProduct.stripe_link_url} target={"_blank"} rel={"noreferrer"}>
                                            <Badge count={t("newsletter-landing-discount")}>
                                                <Button type={"primary"}>
                                                    {(yearlyProduct.price) / 100} {yearlyProduct.currency} / {billingCycleToNamePostName("YEARLY", t)}
                                                </Button>
                                            </Badge>
                                        </a>
                                    )
                                }
                                return (
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Card
                                            bordered={true}
                                            hoverable
                                            actions={actions}
                                            style={{display: 'flex', flexDirection: 'column', flex: 1}}
                                        >
                                            {someProduct.image_url_link && (
                                                <Card.Grid style={cardGridStyle} hoverable={false} className={"p-0"}>
                                                    <div style={{
                                                        overflow: "hidden",
                                                        height: "25vh",
                                                        maxHeight: "40vh",
                                                        background: `url(${someProduct.image_url_link}) no-repeat center center`,
                                                        backgroundSize: "cover"
                                                    }}>
                                                    </div>
                                                </Card.Grid>)}
                                            <Card.Grid style={{width: "100%"}} className={"py-2"} hoverable={false}>
                                                <div className={"display-6"}
                                                     style={{
                                                         textAlign: "center",
                                                         fontSize: "2em"
                                                     }}>{someProduct.product_name}</div>
                                                <Divider className={"my-2"}/>
                                                <div style={{
                                                    width: "100%",
                                                    textAlign: "justify",
                                                    textJustify: "inter-word"
                                                }}>
                                                    {someProduct.product_description
                                                        .split("\n")
                                                        .map(line => line.trim())
                                                        .filter(line => line.length > 0)
                                                        .map((feature: string) => {
                                                            return (
                                                                <li className='mb-1'>
                                                                    <MDBIcon icon='check-circle'
                                                                             className='me-2 text-success'/>{feature}
                                                                </li>
                                                            )
                                                        })}
                                                </div>
                                            </Card.Grid>
                                            <Card.Grid style={
                                                {width: "100%", textAlign: "center"}
                                            } className={"py-1"}
                                                       hoverable={false}>
                                                <Button type={"link"} size={"small"} block
                                                        onClick={() => {
                                                            Modal.info({
                                                                icon: null,
                                                                width: "100%",
                                                                content: <TermsOfService/>,
                                                            })
                                                        }}
                                                >
                                                    {t("newsletter-landing-tos-subscription")}
                                                </Button>
                                            </Card.Grid>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row></>}
                {singlePurchaseProducts.length > 0 && <><Divider><span id={"virtualproducts"}>{t("newsletter-landing-virtual-products")}</span></Divider>
                    <Row gutter={[16, 16]} className={"mx-1"} style={{display: 'flex'}}>
                        {singlePurchaseProducts?.map((product, idx) => {
                            let isNewest = idx === 0
                            return (<>
                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                    <Card
                                        id={isNewest ? "newest" : undefined}
                                        bordered={true}
                                        hoverable
                                        actions={[
                                            <a href={product.stripe_link_url} target={"_blank"} rel={"noreferrer"}
                                               className={"w-75"}>
                                                <Button type={"primary"} size={"large"} block>
                                                    {t("c5n-selling-page-buy-for")} {(product.price) / 100} {product.currency.toUpperCase()}
                                                </Button>
                                            </a>
                                        ]}
                                        style={{display: 'flex', flexDirection: 'column', flex: 1}}
                                    >

                                        {product.image_url_link && (
                                            <Card.Grid style={cardGridStyle} hoverable={false} className={"p-0"}>
                                                <div style={{
                                                    overflow: "hidden",
                                                    alignItems: "center",
                                                    display: "flex"
                                                }}>
                                                    <Image
                                                        src={product.image_url_link}
                                                    />
                                                </div>
                                            </Card.Grid>)}
                                        {isNewest && product.image_url_link ?
                                            <Badge.Ribbon text={t("newsletter-landing-newest-product")} color={"red"}/> : null}
                                        <Card.Grid style={{width: "100%"}} className={"py-2"} hoverable={false}>

                                            <div className={"display-6"}
                                                 style={{
                                                     textAlign: "center",
                                                     fontSize: "1.5em"
                                                 }}>
                                                {product.product_name}
                                            </div>
                                            <Divider className={"my-2"}>
                                                {isNewest && !product.image_url_link ?
                                                    <Badge count={t("newsletter-landing-newest-product")} /> : null}
                                            </Divider>
                                            <div style={{
                                                width: "100%",
                                                textAlign: "justify",
                                                textJustify: "inter-word"
                                            }}>
                                                {product.product_description}
                                            </div>
                                        </Card.Grid>
                                        <Card.Grid style={
                                            {width: "100%", textAlign: "center"}
                                        } className={"py-1"}
                                                   hoverable={false}>
                                            <Button type={"link"} size={"small"} block
                                                    onClick={() => {
                                                        Modal.info({
                                                            icon: null,
                                                            width: "100%",
                                                            content: <C5NTermsOfServiceBuyer/>,
                                                        })
                                                    }}
                                            >
                                                {t("newsletter-landing-tos-products")}
                                            </Button>
                                        </Card.Grid>
                                    </Card>
                                </Col>
                            </>)
                        })}
                    </Row></>}
            </Content>
        </Layout>
    )
}

