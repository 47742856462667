import React from 'react';
import ReactGA from "react-ga4";
export const TermsOfService = () => {
    ReactGA.send(
        {
            hitType: "pageview",
            page: "tos",
        });
    return <p>
        <h2>1. Postanowienia ogólne</h2>
        <p>1.1. Niniejszy regulamin (dalej: "Regulamin") określa zasady korzystania z serwisu subdog.xyz
            (dalej: "Serwis") oraz prawa i obowiązki użytkowników Serwisu.</p>
        <p>1.2. Serwis subdog.xyz jest prowadzony przez sawanna - Piotr Lewandowski, NIP: 5020104314, email:
            piotr.w.lewandowski+sd@gmail.com (dalej: "Operator").</p>
        <p>1.3. Korzystając z Serwisu, użytkownik akceptuje postanowienia niniejszego Regulaminu oraz
            zobowiązuje się do jego przestrzegania.</p>

        <h2>2. Usługa subskrypcji SMS</h2>
        <p>2.1. Serwis subdog.xyz umożliwia użytkownikom wykupienie subskrypcji SMS, która pozwala na
            otrzymywanie codziennych wiadomości SMS od Twórców Treści (dalej: "Subskrypcja SMS").</p>
        <p>2.2. Subskrypcja SMS jest płatna i dostępna na zasadach określonych na stronie internetowej
            Serwisu.</p>
        <p>2.3. Płatności za Subskrypcję SMS są przetwarzane przez system płatności Stripe, a wszelkie
            informacje związane z płatnościami podlegają regulacjom Stripe.</p>


        <h2>3. Przetwarzanie danych osobowych</h2>
        <p>3.1. Operator Serwisu przetwarza dane osobowe użytkowników, takie jak numer telefonu i adres
            e-mail, wyłącznie w celu umożliwienia dostarczania wiadomości SMS oraz zarządzania kontem
            użytkownika.</p>
        <p>3.2. Operator zapewnia, że dane osobowe użytkowników są przetwarzane zgodnie z obowiązującymi
            przepisami prawa polskiego oraz unii europejskiej.</p>
        <p>3.3. Operator nie sprzedaje danych osobowych użytkowników ani nie udostępnia ich osobom trzecim, za wyjątkiem Twórców, którym te dane są potrzebne do wystawiania rachunków oraz faktur.</p>
        <p>3.4. Operator nie przechowuje danych kart płatniczych Użytkowników. Dane te są przetwarzane
            bezpiecznie przez system płatności.</p>

        <h2>4. Prawa i obowiązki użytkownika</h2>
        <p>4.1. Użytkownik może korzystać z Serwisu wyłącznie w celach osobistych i niekomercyjnych.</p>
        <p>4.2. Użytkownik zobowiązuje się do zachowania poufności swojego hasła dostępowego do Serwisu oraz
            nie może udostępniać go osobom trzecim.</p>
        <p>4.3. Użytkownik nie może dokonywać nieautoryzowanych prób dostępu do systemu Serwisu ani naruszać
            jego bezpieczeństwa.</p>
        <p>4.4. Użytkownik Serwisu zobowiązuje się do korzystania z Serwisu zgodnie z obowiązującymi
            przepisami prawa, dobrymi obyczajami oraz postanowieniami niniejszego Regulaminu.</p>
        <p>4.5. Użytkownik ma prawo w dowolnym momencie zrezygnować z Subskrypcji SMS poprzez kontakt z
            Obsługą Klienta Serwisu lub przez Konto Użytkownika.</p>
        <p>4.6. Użytkownik ponosi odpowiedzialność za prawidłowe podanie swojego numeru telefonu i adresu
            e-mail podczas zakładania konta w Serwisie.</p>

        <h2>5. Postanowienia końcowe</h2>
        <p>5.1. Operator zastrzega sobie prawo do zmiany niniejszego Regulaminu w dowolnym czasie. Zmiany
            Regulaminu będą publikowane na stronie internetowej Serwisu i wchodzą w życie od daty ich
            opublikowania.</p>
        <p>5.2. Użytkownik ma obowiązek regularnie sprawdzać Regulamin w celu zapoznania się z ewentualnymi
            zmianami.</p>
        <p>5.3. Wszelkie spory wynikające z korzystania z Serwisu będą rozstrzygane przez właściwe sądy na
            terenie Polski.</p>
        <p>5.4. Niniejszy Regulamin jest zgodny z obowiązującym prawem polskim oraz unii europejskiej.</p>
        <p>5.5. Kontakt z Obsługą Klienta Serwisu możliwy jest poprzez adres e-mail:
            piotr.w.lewandowski+sd@gmail.com</p>

        <p>Niniejszy Regulamin wchodzi w życie z dniem opublikowania na stronie internetowej Serwisu.</p>
    </p>
}