import React from "react";
import {Button, Card, Form, Input, message} from "antd";
import axios from "axios";
import {useTranslation} from "react-i18next";


export let AuthScreen = () => {
    const signIn = React.useCallback(async ({email}: { email: string }) => {
        return requestMagicLink(email)
    }, [])
    const {t} = useTranslation()
    const onSubmit = async (e: any) => {
        let values = {
            email: e['user-email']
        }
        try {
            await signIn(values)
            message.success(t("user-login-check-email"), 5)
        } catch (e: any) {
            message.success(t("user-login-check-email"), 5)
        } finally {
        }
    }

    return (
        <Card style={{minWidth: "50vh"}} className={"mt-3"}>
            <Form
                name={"customer-login-link"}
                initialValues={{remember: true}}
                size={"large"}
                onFinish={onSubmit}
            >
                <p style={{
                    textAlign: "justify",
                    textJustify: "inter-word"
                }}>{t("user-login-explanation-on-form")}</p>
                <Form.Item<string>
                    label={t("user-login-address-email")}
                    name="user-email"
                    rules={[
                        {required: true, message: t("user-login-email-required")},
                        {type: 'email', message: t("user-login-email-format")}
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        {t("user-login-form-button-send-link")}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export const requestMagicLink = async (email: string) => {
    const res = await axios.post(
        "https://hcr2fnxpe8.execute-api.eu-west-1.amazonaws.com/prod/sign_in_customers_fast",
        {email}
    )
    return res.data
}