export const WebTranslations = {
    resources: {
        en: {
            translation: {
                "retrieve-c5ns-title": "Retrieve content you purchased on subdog's c5n",
                "retrieve-c5ns-explanation": "Enter email address you have used for purchases below. I'll send you a message that contains all links to your content.",
                "email-address-label": "Your email address",
                "retrieve-c5ns-button-label": "Retrieve my content!",
                "c5n-after-retrieve-explanation": "You should receive an email with links to your content. If you don't after 20 minutes, please contact me at piotr.w.lewandowski+c5n@gmail.com",

                "c5n-selling-page-title": "Best virtual content by",
                "c5n-selling-page-by": "by",
                "c5n-selling-page-information-rules": "By purchasing you agree to following",
                "c5n-selling-page-information-link-name": "terms of service",
                "here": "here",

                "c5n-selling-retrieve-bought-product": "If you want to retrieve products you bought, click",

                "newsletter-landing-subscriptions-top-row": "Subscriptions",
                "newsletter-landing-products-top-row": "Products",
                "newsletter-landing-newset-product-top-row": "Newest product",
                "newsletter-landing-newest-product": "Newest product",
                "newsletter-landing-discount": "Discount!",
                "newsletter-landing-tos-subscription": "Terms of service",
                "newsletter-landing-tos-products": "Terms of service",
                "newsletter-landing-subscriptions": "Subscriptions",
                "newsletter-landing-virtual-products": "Virtual products",
                "c5n-selling-page-year": "Year",
                "c5n-selling-page-month": "Month",
                "c5n-selling-page-once": "Once",
                "c5n-selling-page-buy-for": "Buy for",

                "user-login-how-to-setup-account": "How to setup account?",
                "user-login-how-to-setup-description": "Your account will be automatically created when you purchase a subscription or product from one of the Creators.",

                "user-login-check-email": "Check your email in a moment!",
                "user-login-explanation-on-form": "If you have a subscription or product, I'll send you a special login link to your email",
                "user-login-address-email": "Email address",
                "user-login-email-required": "Please provide your email address!",
                "user-login-email-format": "This is not a valid email address!",
                "user-login-form-button-send-link": "Send login link",

                "user-panel-file-library": "File library",
                "user-panel-account-management": "Account and payments",
                "user-panel-log-out": "Log out",

                "file-library-generate-download-link": "Generate secure download link",
                "file-library-download-file": "Download file",

                "user-management-payment-management": "Payment management",
                "user-management-payment-management-description": "Managing subscriptions, payments, changing phone number is done via stripe.com portal.",
                "user-management-payment-management-go-to-stripe": "Go to Stripe",

                "user-management-deletion-modal-title": "Are you sure you want to delete your account?",
                "user-management-deletion-modal-content": "Cancel all subscriptions, lose access to products and delete your account. You can contact me through chat icon in the corner.",
                "user-management-deletion-modal-ok-text": "Yes, delete account",
                "user-management-deletion-modal-cancel": "Cancel",

                "user-management-deletion-card-title": "Deleting account",
                "user-management-deletion-card-description": "Cancel all subscriptions, lose access to products and delete your account",
                "user-management-deletion-card-button-text": "Yes, I want to cancel!",

                "user-feedback-modal-tooltip": "Contact me!",
                "user-feedback-modal-description": "Mail",
                "user-feedback-modal-text": "If I can improve something, let me know. I'm building subdog.xyz service by myself, so please be understanding!",
                "user-feedback-modal-your-email": "Your email address",
                "user-feedback-modal-your-message": "Your message",
                "user-feedback-modal-send": "Send",
                "user-feedback-modal-close": "Close",
            }
        },
        pl: {
            translation: {
                "retrieve-c5ns-title": "Odzyskaj treści zakupione na subdogowym c5n",
                "retrieve-c5ns-explanation": "Wpisz poniżej adres email użytego do zakupów. Wyślę Ci wiadomość z linkami do zakupionych przez Ciebie treści.",
                "email-address-label": "Twój adres email",
                "retrieve-c5ns-button-label": "Odzyskaj moje treści!",
                "c5n-after-retrieve-explanation": "Powinieneś otrzymać email z linkami do zakupionych treści. Jeśli nie dostaniesz go w ciągu 20 minut, proszę napisz do mnie na piotr.w.lewandowski+c5n@gmail.com",

                "c5n-selling-page-title": "Najlepsze wirtualne treści od",
                "c5n-selling-page-by": "stworzone przez",
                "c5n-selling-page-information-rules": "Kupując zgadzasz się na",
                "c5n-selling-page-information-link-name": "regulamin",
                "here": "tutaj",

                "c5n-selling-retrieve-bought-product": "Jeśli chcesz odzyskać zakupione treści, kliknij",

                "newsletter-landing-subscriptions-top-row": "Subskrypcje",
                "newsletter-landing-products-top-row": "Produkty",
                "newsletter-landing-newset-product-top-row": "Najnowszy produkt",
                "newsletter-landing-newest-product": "Najnowszy produkt",
                "newsletter-landing-discount": "Zniżka!",
                "newsletter-landing-tos-subscription": "Regulamin",
                "newsletter-landing-tos-products": "Regulamin",
                "newsletter-landing-subscriptions": "Subskrypcje",
                "newsletter-landing-virtual-products": "Produkty wirtualne",
                "c5n-selling-page-year": "Rok",
                "c5n-selling-page-month": "Miesiąc",
                "c5n-selling-page-once": "Jednorazowo",
                "c5n-selling-page-buy-for": "Kup za",

                "user-login-how-to-setup-account": "Jak założyć konto?",
                "user-login-how-to-setup-description": "Twoje konto zostanie automatycznie założone, gdy wykupisz subskrypcję lub produkt u jednego z Twórców.",

                "user-login-check-email": "Za kilka chwil sprawdź swój adres email!",
                "user-login-explanation-on-form": "Jeżeli posiadasz subskrypcje lub produkt, na Twój adres email wyślę specjalny link do logowania",
                "user-login-address-email": "Adres email",
                "user-login-email-required": "Proszę podaj swój adres email!",
                "user-login-email-format": "To nie jest poprawny adres email!",
                "user-login-form-button-send-link": "Wyślij link do logowania",

                "user-panel-file-library": "Biblioteka plików",
                "user-panel-account-management": "Konto i płatności",
                "user-panel-log-out": "Wyloguj się",

                "file-library-generate-download-link": "Wygeneruj bezpieczny link do pobrania",
                "file-library-download-file": "Pobierz plik",

                "user-management-payment-management": "Zarządzanie płatnościami",
                "user-management-payment-management-description": "Zarządzanie subsrypcjami, płatnościami, zmiana numeru telefonu odbywa się przez portal stripe.com.",
                "user-management-payment-management-go-to-stripe": "Idź do portalu Stripe",

                "user-management-deletion-modal-title": "Czy na pewno chcesz usunąć konto?",
                "user-management-deletion-modal-content": "Zrezygnuj ze wszystkich subskrypcji, strać dostęp do produktów i usuń konto. Możesz się ze mną skontaktować poprzez ikonę czatu w rogu strony.",
                "user-management-deletion-modal-ok-text": "Tak, usuń konto",
                "user-management-deletion-modal-cancel": "Anuluj",

                "user-management-deletion-card-title": "Usuwanie konta",
                "user-management-deletion-card-description": "Zrezygnuj ze wszystkich subskrypcji, strać dostęp do produktów i usuń konto",
                "user-management-deletion-card-button-text": "Tak, chcę zrezygnować!",

                "user-feedback-modal-tooltip": "Skontaktuj się ze mną!",
                "user-feedback-modal-description": "Mail",
                "user-feedback-modal-text": "Jeżeli mogę coś polepszyć, daj mi znać. Buduję serwis subdog.xyz sam, więc proszę o wyrozumiałość!",
                "user-feedback-modal-your-email": "Twój adres email",
                "user-feedback-modal-your-message": "Twoja wiadomość",
                "user-feedback-modal-send": "Wyślij",
                "user-feedback-modal-close": "Zamknij",
            }
        }
    },
    // lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
}