import * as React from 'react'
import {useLocation} from 'react-router-dom'
import {message} from 'antd'
import {WaitingScreen} from "./WaitingScreen";
import {Auth} from "aws-amplify";

export const VerifyMagicLink = () => {
    const isAuthenticated = React.useCallback(async () => {
        try {
            await Auth.currentSession()
            return true
        } catch (error) {
            return false
        }
    }, [])
    const answerCustomChallenge = async (email: string, answer: string) => {
        let cognitoUser = await Auth.signIn(email)
        await Auth.sendCustomChallengeAnswer(cognitoUser, answer)
        return isAuthenticated()
    }

    const location = useLocation()

    React.useEffect(() => {
        const queryString = location.search.slice(1);
        const params = queryString.split('&').reduce((params, param) => {
            const [key, value] = param.split('=');
            params[key] = decodeURIComponent(value);
            return params;
        }, {} as any);
        const email = params.email;
        const answer = params.code;
        answerCustomChallenge(email, answer)
            .then(() => {
                window.location.replace('/user-access');
            })
            .catch((e: any) => {
                console.log(e)
                message.error('Invalid login link!')
            })
    }, [])

    return <WaitingScreen/>
}


