import {Navigate, Route, Routes, useParams} from "react-router-dom";
import App from "./App";
import React, {useEffect} from "react";
import {NewsletterLanding} from "./NewsletterLanding";
import {StandardPage} from "./StandardPage";
import {TopNavigationUserData} from "./TopNavigation";
import {AwsAuthenticatorComponent} from "./AwsAuthenticator";
import ReactGA from "react-ga4";
import {TermsOfService} from "./TermsOfService";
import {MDBCard} from "mdb-react-ui-kit";
import {C5NUserRetrievalPage} from "../c5n/C5NUserRetrievalPage";
import {C5NSellingPage} from "../c5n/C5NSellingPage";
import {C5NTermsOfServiceBuyer} from "../c5n/C5NTermsOfServiceBuyer";
import {VerifyMagicLink} from "./auth/VerifyMagicLink";
import {UserPanel} from "../user_panel/UserPanel";
import {Authenticator} from "@aws-amplify/ui-react";

interface SubGacekRoutesProps {
    userDataProvider: () => TopNavigationUserData | undefined
    testSetup?: boolean
}

export const SubGacekRoutes = ({userDataProvider, testSetup}: SubGacekRoutesProps) => {

    return (
        <Routes>
            <Route path="/" element={<Navigate to={"/user-access"}/>}>
            </Route>
            <Route path={"/c5n"}
                   element={<C5NUserRetrievalPage/>}/>
            <Route path={"/c5n/:slug"}
                   element={<C5NSellingPage routerParams={useParams}/>}

            />
            <Route path={"/:newsletterName"}
                   element={<NewsletterLanding
                       routerParams={useParams}/>}/>
            <Route path={"/user-access"}
                   element={<>
                       <div style={{display: "none"}}>
                           <Authenticator/>
                       </div>
                       <AwsAuthenticatorComponent/></>}/>
            <Route path={"/terms-of-service"}
                   element={<StandardPage userDataProvider={userDataProvider}
                                          testSetup={testSetup}>
                       <MDBCard>
                           <div className={""}>
                               <TermsOfService/>
                           </div>
                       </MDBCard>
                   </StandardPage>}/>
            <Route path={"/terms-of-service-c5n-buyer"}
                   element={<C5NTermsOfServiceBuyer/>}/>
            <Route path={"/verify_magic_link"} element={<VerifyMagicLink/>}/>

        </Routes>
    )
}
