import {Link, Params} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter, MDBCardImage, MDBCardOverlay,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBRow
} from "mdb-react-ui-kit";
import {useTranslation} from "react-i18next";
import {Alert} from "react-bootstrap";

export interface C5NSellingResponse {
    products: C5NProduct[]
}

export interface C5NProduct {
    product_name: string,
    product_description: string,
    price: number,
    currency: string,
    stripe_link_url: string,
    last_update_time_epoch_secs: number,
    author_name: string,
    image_url_link: string | undefined,
    payment_type: string
}

export const C5NSellingPage = ({routerParams}: C5NSellingPageParams) => {
    const {t} = useTranslation();
    let {slug} = routerParams();
    let apiEndpoint: string | undefined = process.env.REACT_APP_API_ENDPOINT;
    let [storedC5nResponse, setStoredC5nResponse] = useState(undefined as C5NSellingResponse | undefined)
    useEffect(() => {
        let f = async () => {
            let response = await fetch(apiEndpoint + 'c5n_selling?slug=' + slug).catch(_ => undefined)
            if (response !== undefined && response.ok && response.body !== null) {
                setStoredC5nResponse(await response.json() as C5NSellingResponse)
            }
        }
        f()
    }, [slug]);
    if (slug === undefined) {
        return (<></>)
    }
    if (storedC5nResponse === undefined) {
        return (<></>)
    }

    if (storedC5nResponse.products.length === 0) {
        return (<></>)
    }
    let authorName = storedC5nResponse.products[0].author_name

    return (
        <MDBContainer fluid>
            <MDBRow>
                <MDBCol>
                    <C5NRetrieveAlert/>
                </MDBCol>
            </MDBRow>
            <MDBRow className={"mt-1"}>
                <p className={"display-4 text-center"}>{t("c5n-selling-page-title")} {authorName}</p>
            </MDBRow>
            <C5NSellingRow products={storedC5nResponse.products}/>
        </MDBContainer>
    )
}

export const C5NRetrieveAlert = () => {
    const {t} = useTranslation();
    return (
        <Alert className={"py-2 mt-1 text-center"}>{t("c5n-selling-retrieve-bought-product")}&nbsp;
            <Link
                to={"/c5n"}>{t("here")}</Link></Alert>
    )

}
export const C5NSellingRow = ({products}: C5NSellingRowProps) => {
    const {t} = useTranslation();
    return (<MDBRow className='row-cols-1 row-cols-lg-2 g-2' center>
        {products.map((product) => {
            const image_url_is_valid = product.image_url_link !== null && product.image_url_link !== undefined  && isValidHttpUrl(product.image_url_link)
            return (
                <MDBCol>
                    <MDBCard border={"2"} shadow={"5"}>
                        {image_url_is_valid && <MDBCardImage
                            style={{objectFit: "cover", maxHeight: "40vh"}}
                            src={product.image_url_link}
                        />}

                        <MDBCardBody className={"pt-2"}>
                            {!image_url_is_valid &&
                                <MDBCardTitle style={{fontWeight: 380, lineHeight: 1.4}}
                                              className={"text-center h5"}>"{product.product_name.trim()}"<br/>{t("c5n-selling-page-by")} {product.author_name}
                                </MDBCardTitle>}
                            {image_url_is_valid &&
                                <MDBCardOverlay>
                                    <div className={"text-bg-light rounded-4"} style={{opacity: 0.8}}>
                                        <MDBCardTitle style={{fontWeight: 380, lineHeight: 1.4}}
                                                      className={"text-center h5"}>"{product.product_name.trim()}"<br/>{t("c5n-selling-page-by")} {product.author_name}
                                        </MDBCardTitle>
                                    </div>
                                </MDBCardOverlay>
                            }
                            <MDBCardText>
                                <div style={{
                                    textAlign: "justify",
                                    textJustify: "inter-word"
                                }}>{product.product_description}</div>
                                <a href={product.stripe_link_url} target={"_blank"} rel={"noreferrer"}>
                                    <MDBBtn block size={"lg"} outline rounded
                                            className={'mt-2 text-center'} color='info'>
                                        Kup za {(product.price) / 100} {product.currency}
                                    </MDBBtn>
                                </a>
                            </MDBCardText>
                        </MDBCardBody>
                        <MDBCardFooter>
                            {t("c5n-selling-page-information-rules")} <Link
                            to={"/terms-of-service-c5n-buyer"}>{t("c5n-selling-page-information-link-name")}</Link>
                        </MDBCardFooter>
                    </MDBCard>
                </MDBCol>
            )
        })}
    </MDBRow>);
}

function isValidHttpUrl(string: string): boolean {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export interface C5NSellingRowProps {
    products: C5NProduct[]

}

interface C5NSellingPageParams {
    routerParams: () => Readonly<Params>
}
