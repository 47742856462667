import React from "react";
import {Container, Row} from "react-bootstrap";
import './StandardPage.css';
import {TopNavigation, TopNavigationUserData} from "./TopNavigation";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {Auth} from "aws-amplify";

type StandardPageProps = {
    children: React.ReactNode
    userDataProvider: () => TopNavigationUserData | undefined
    testSetup?: boolean
}

export const useAwsAuthenticatorDataProvider: () => TopNavigationUserData | undefined = () => {
    const {user} = useAuthenticator()
    let userData: TopNavigationUserData | undefined
    if (user?.attributes?.email !== undefined) {
        userData = {displayName: user.attributes.email, logOutAction: () => Auth.signOut()}
    }
    return userData
}

export function StandardPage({children, userDataProvider, testSetup}: StandardPageProps) {
    let userData = userDataProvider()
    return (
        <div className={"StandardPage h-100"}>
            <Container className="px-lg-5 py-1 bg-dark">
                <TopNavigation userData={userData} testSetup={testSetup}/>
                <Row className={"pt-lg-2"}>
                    {children}
                </Row>
            </Container>

        </div>
    );

}