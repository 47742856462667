import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBTabs,
    MDBTabsContent,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsPane
} from "mdb-react-ui-kit";
import Markdown from "react-markdown";
import React, {useState} from "react";

const platformName = "subdog.xyz c5n"
const email = "piotr.w.lewandowski+c5n@gmail.com"

const polishTerms = `## Warunki Korzystania dla Kupujących na ${platformName}\n` +
    '\n' +
    `**Witamy na ${platformName}!**\n` +
    '\n' +
    `Niniejsze Warunki Korzystania („Warunki”) regulują Twój dostęp i korzystanie z platformy ${platformName}. Korzystając z platformy, potwierdzasz, że przeczytałeś, zrozumiałeś i zgodziłeś się na przestrzeganie niniejszych Warunków. Jeśli nie zgadzasz się z jakąkolwiek częścią niniejszych Warunków, nie możesz korzystać z platformy.\n` +
    '\n' +
    '**1. Definicje**\n' +
    '\n' +
    '* **Kupujący:** Ty, użytkownik platformy, który kupuje wirtualne produkty od Twórców.\n' +
    '* **Twórca:** Osoba fizyczna lub podmiot sprzedający wirtualne produkty na platformie.\n' +
    '* **Treść:** Wirtualne produkty dostępne do zakupu na platformie, w tym między innymi oprogramowanie, szablony, e-booki, projekty itp.\n' +
    `* **${platformName}:** Platforma ułatwiająca sprzedaż wirtualnych produktów od Twórców do Kupujących.\n` +
    '\n' +
    '**2. Treść i Twórcy**\n' +
    '\n' +
    `* Rozumiesz, że Twórcy ponoszą całkowitą odpowiedzialność za treść, którą oferują na platformie. ${platformName} nie kontroluje, nie popiera ani nie gwarantuje jakości, bezpieczeństwa ani legalności żadnej treści.\n` +
    `* ${platformName} nie jest stroną żadnej umowy między Tobą a Twórcą. Wszystkie zakupy są dokonywane bezpośrednio u Twórcy za pośrednictwem Stripe.\n` +
    `* W przypadku wątpliwości dotyczących treści, prosimy o bezpośredni kontakt z Twórcą. Jeśli uważasz, że treść narusza niniejsze Warunki lub obowiązujące przepisy prawa, zgłoś to do ${platformName}.\n` +
    '\n' +
    '**3. Dostawa i Dostęp do Treści**\n' +
    '\n' +
    `* Po zakupie treść zostanie wysłana na Twój adres e-mail powiązany z kontem ${platformName}.\n` +
    `* Zawsze możesz odzyskać treść, podając swój adres e-mail i szczegóły zamówienia w ${platformName}.\n` +
    '* Przyjmujesz do wiadomości, że dostęp do treści może być uzależniony od połączenia internetowego i innych ograniczeń technicznych.\n' +
    '\n' +
    '**4. Płatności i Zwroty**\n' +
    '\n' +
    '* Wszystkie płatności są przetwarzane za pośrednictwem Stripe, bezpiecznego procesora płatności zewnętrznego.\n' +
    '* Zasady zwrotów są ustalane przez każdego Twórcę indywidualnie. Szczegóły należy sprawdzić w opisie konkretnego produktu.\n' +
    `* ${platformName} nie ponosi odpowiedzialności za żadne problemy z płatnościami ani spory. W przypadku problemów z płatnościami, prosimy o bezpośredni kontakt z Stripe.\n` +
    '\n' +
    '**5. Twoje Dane i Prywatność**\n' +
    '\n' +
    '* Gromadzimy Twój adres e-mail i inne informacje niezbędne do przetworzenia Twojego zakupu i dostarczenia treści.\n' +
    '* Nie będziemy udostępniać Twojego adresu e-mail ani żadnych innych danych osobowych podmiotom zewnętrznym. Twórcy mają dostęp do Twojego adresu email, gdyż znajduje się na fakturze.\n' +
    '* Możemy użyć Twojego adresu e-mail, aby skontaktować się z Tobą w sprawie Twoich zakupów, aktualizacji produktów i ważnych ogłoszeń platformy. Możesz w każdej chwili zrezygnować z tych komunikatów.\n' +
    '* Aby uzyskać więcej informacji o naszych praktykach dotyczących danych, zapoznaj się z naszą Polityką Prywatności.\n' +
    '\n' +
    '**6. Ogólne Warunki Prawne**\n' +
    '\n' +
    '* Niniejsze Warunki podlegają i są interpretowane zgodnie z prawem polskim i prawem Unii Europejskiej.\n' +
    '* Zgadzasz się na rozstrzyganie wszelkich sporów wynikających z niniejszych Warunków za pomocą wiążącego arbitrażu w Polsce.\n' +
    '* Renuncjujesz z prawa do rozprawy przed ławą przysięgłych lub udziału w zbiorowym pozwie.\n' +
    `* Niniejsze Warunki stanowią całość umowy między Tobą a ${platformName} dotyczącą Twojego korzystania z platformy.\n` +
    '\n' +
    '**7. Zgoda na RODO**\n' +
    '\n' +
    `Korzystając z ${platformName}, wyrażasz zgodę na gromadzenie, wykorzystywanie i przechowywanie Twoich danych osobowych, zgodnie z niniejszymi Warunkami.\n` +
    '\n' +
    '**8. Kontakt**\n' +
    '\n' +
    `W razie pytań dotyczących niniejszych Warunków prosimy o kontakt pod adresem ${email}.\n` +
    '\n'

const englishTerms = `## Terms of Service for Buyers on ${platformName}\n` +
    '\n' +
    `**Welcome to ${platformName}!**\n` +
    '\n' +
    `These Terms of Service ("Terms") govern your access and use of ${platformName}. By accessing or using our platform, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you disagree with any part of these Terms, you may not access or use the platform.\n` +
    '\n' +
    '**1. Definitions**\n' +
    '\n' +
    '* **Buyer:** You, the user of the platform who purchases virtual products from Creators.\n' +
    '* **Creator:** An individual or entity selling virtual products on the platform.\n' +
    '* **Content:** The virtual products available for purchase on the platform, including but not limited to software, templates, ebooks, designs, etc.\n' +
    `* **${platformName}:** The platform facilitating the sale of virtual products from Creators to Buyers.\n` +
    '\n' +
    '**2. Content and Creators**\n' +
    '\n' +
    `* You understand that Creators are solely responsible for the content they offer on the platform. ${platformName} does not control, endorse, or guarantee the quality, safety, or legality of any content.\n` +
    `* ${platformName} is not a party to any contract between you and a Creator. All purchases are made directly with the Creator through Stripe.\n` +
    `* If you have any concerns about content, please contact the Creator directly. If you believe the content violates these Terms or applicable laws, please report it to ${platformName}.\n` +
    '\n' +
    '**3. Delivery and Access to Content**\n' +
    '\n' +
    `* Upon purchase, the content will be delivered to your email address associated with your ${platformName} account.\n` +
    `* You can always retrieve the content by providing your email address and order details to ${platformName}.\n` +
    '* You acknowledge that access to the content may be subject to internet connectivity and other technical limitations.\n' +
    '\n' +
    '**4. Payment and Refunds**\n' +
    '\n' +
    '* All payments are processed through Stripe, a secure third-party payment processor.\n' +
    '* Refund policies are set by each Creator individually. Please refer to the specific product description for details.\n' +
    `* ${platformName} is not responsible for any payment issues or disputes. For assistance with payments, please contact Stripe directly.\n` +
    '\n' +
    '**5. Your Data and Privacy**\n' +
    '\n' +
    '* We collect your email address and other information necessary to process your purchase and deliver the content.\n' +
    '* We will not share your email address or any other personal information with external parties. Creators will have access to your email address, as it will be available in the invoice.\n' +
    '* We may use your email address to contact you about your purchases, product updates, and important platform announcements. You can opt out of these communications at any time.\n' +
    '* For more information about our data practices, please see our Privacy Policy.\n' +
    '\n' +
    '**6. General Legal Terms**\n' +
    '\n' +
    '* These Terms are governed by and construed in accordance with the laws of Poland and the European Union.\n' +
    '* You agree to resolve any disputes arising out of these Terms through binding arbitration in Poland.\n' +
    '* You waive any right to a jury trial or to participate in a class action lawsuit.\n' +
    `* These Terms constitute the entire agreement between you and ${platformName} concerning your use of the platform.\n` +
    '\n' +
    '**7. GDPR Consent**\n' +
    '\n' +
    `By using ${platformName}, you consent to the collection, use, and storage of your personal information as described in these Terms.\n` +
    '\n' +
    '**8. Contact Us**\n' +
    '\n' +
    `If you have any questions about these Terms, please contact us at ${email}.\n`

export const C5NTermsOfServiceBuyer = () => {
    const [fillActive, setFillActive] = useState('tab1');

    const handleFillClick = (value: string) => {
        if (value === fillActive) {
            return;
        }

        setFillActive(value);
    };

    return (
        <MDBContainer fluid>
            <MDBRow>
                <MDBCol>
                    <MDBTabs fill className='mb-3'>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
                                Polski
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleFillClick('tab2')} active={fillActive === 'tab2'}>
                                English
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>

                    <MDBTabsContent>
                        <MDBTabsPane show={fillActive === 'tab1'}><Markdown>{polishTerms}</Markdown></MDBTabsPane>
                        <MDBTabsPane show={fillActive === 'tab2'}><Markdown>{englishTerms}</Markdown></MDBTabsPane>
                    </MDBTabsContent>
                </MDBCol>
            </MDBRow>
        </MDBContainer>)
}