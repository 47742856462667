import React, {useState} from 'react';
import {LogoutOutlined, ReadOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Card, Col, Layout, Menu, Modal, Row, Skeleton, Space, Watermark} from 'antd';
import {MenuDividerType, MenuItemType} from "antd/lib/menu/hooks/useItems";
import {API, Auth} from "aws-amplify";
import {AuthenticatedUserData, SignedContentResponse} from "../components/AwsAuthenticator";
import {useTranslation} from "react-i18next";

const {Content, Sider} = Layout;

export interface UserPanelProps {
    userData: AuthenticatedUserData
}

export const UserPanel = ({userData}: UserPanelProps) => {
    const hasFileLibraryProducts = userData.current_product_data.length > 0
    let [content, setContent] = useState(hasFileLibraryProducts ?
        <FileLibraryContent products={userData.current_product_data}/> : <AccountManagementContent/>)
    return (
        <Layout style={{minHeight: "100vh"}}>
            <Sider
                breakpoint="lg"
                width={220}
                collapsedWidth="0"
            >
                <div className="demo-logo-vertical"/>
                <UserMenu setContent={setContent} userData={userData}/>
            </Sider>
            <Layout>
                {content}
            </Layout>
        </Layout>
    )
}

interface UserMenuProps {
    userData: AuthenticatedUserData,
    setContent: (content: any) => void,
}

const UserMenu = ({userData, setContent}: UserMenuProps) => {
    const {t} = useTranslation();
    let items = [AccountManagementMenuItem(t), {} as MenuDividerType, LogoutMenuItem(t)]
    if (userData.current_product_data.length > 0) {
        items.unshift(FileLibraryMenuItem(t))
    }
    return (<Menu theme="dark" mode="inline" defaultSelectedKeys={['file-library']}
                  items={items}
                  onClick={menuItemClickHandler(userData, setContent)}>
    </Menu>)
}

const menuItemClickHandler = (userData: AuthenticatedUserData, setContent: (content: any) => void) => (e: any) => {
    if (e.key === LogoutKey) {
        Auth.signOut().then(() => {
            window.location.reload();
        });
    } else if (e.key === AccountManagementKey) {
        setContent(<AccountManagementContent/>)
    } else if (e.key === FileLibraryKey) {
        setContent(<FileLibraryContent products={userData.current_product_data}/>)
    }

}

const LogoutKey = 'logout'

function LogoutMenuItem(t: (key: string) => string): MenuItemType {
    return {
        key: LogoutKey,
        danger: true,
        icon: React.createElement(LogoutOutlined),
        label: t("user-panel-log-out"),
    }
}

const AccountManagementKey = 'account-management'

function AccountManagementMenuItem(t: (key: string) => string): MenuItemType {
    return {
        key: AccountManagementKey,
        icon: React.createElement(UserOutlined),
        label: t("user-panel-account-management"),
    }
}

const AccountManagementContent = () => {
    const {t} = useTranslation();
    return (
        <Space>
            <Row>
                <Col xs={{span: 24}} lg={{span: 12}} style={{padding: 25, paddingTop: 10}}>
                    <Card title={t("user-management-payment-management")} bordered={false}>
                        <p style={{
                            textAlign: "justify",
                            textJustify: "inter-word"
                        }}>
                            {t("user-management-payment-management-description")}
                        </p>
                        <a href={"https://billing.stripe.com/p/login/cN228IdQJ5j89b28ww"} target={"_blank"}
                           rel="noreferrer">
                            <Button type="primary" block>
                                {t("user-management-payment-management-go-to-stripe")}
                            </Button>
                        </a>
                    </Card>
                </Col>
                <Col xs={{span: 24}} lg={{span: 12}} style={{padding: 25, paddingTop: 10}}>
                    <DeleteAccountCard/>
                </Col>
            </Row>
        </Space>
    )
}

const DeleteAccountCard = () => {
    const {t} = useTranslation();
    const DeletionModal = () => Modal.confirm({
        title: t("user-management-deletion-modal-title"),
        content: t("user-management-deletion-modal-content"),
        onOk: async () => {
            const requestParams = {
                queryStringParameters: {
                    delete_all: true
                }
            }
            await API.post("BackendAuth", "subscription_delete", requestParams).then(async () => {
                await Auth.signOut();
                window.location.reload();
            })
        },
        okText: t("user-management-deletion-modal-ok-text"),
        cancelText: t("user-management-deletion-modal-cancel"),
    })
    return (
        <>
            <Card title={t("user-management-deletion-card-title")} bordered={false}>
                <p style={{
                    textAlign: "justify",
                    textJustify: "inter-word"
                }}>
                    {t("user-management-deletion-card-description")}
                </p>
                <Button type={"primary"} danger block onClick={DeletionModal}>
                    {t("user-management-deletion-card-button-text")}
                </Button>
            </Card>
        </>
    )
}

const FileLibraryKey = 'file-library'

function FileLibraryMenuItem(t: (key: string) => string): MenuItemType {
    return {
        key: FileLibraryKey,
        icon: React.createElement(ReadOutlined),
        label: t("user-panel-file-library"),
    }
}

interface FileLibraryContentProps {
    products: UserFileProduct[]
}

const FileLibraryContent = ({products}: FileLibraryContentProps) => {
    const {t} = useTranslation();
    let [responsesMap, setResponsesMap] = useState<Map<number, SignedContentResponse>>(new Map())
    return (
        <Content style={{margin: '24px 16px 0'}}>
            <Row gutter={[16, 16]}>

                {products.map((product) => (
                    <Col xs={24} sm={12} md={12} lg={8} xl={8} key={product.product_id}>
                        <Card
                            hoverable
                            cover={
                                <div style={{height: '200px', overflow: 'hidden'}}>
                                    {product.image_url_link ? (
                                        <img alt="example" src={product.image_url_link}
                                             style={{width: '100%', height: 'auto'}}/>
                                    ) : (
                                        <Watermark content={product.product_name}>
                                            <div style={{height: '200px', overflow: 'hidden'}}></div>
                                        </Watermark>
                                    )}
                                </div>

                            }
                            actions={[
                                <Button type="primary" onClick={async e => {
                                    let m = Modal.info({
                                        content: <Skeleton active/>,
                                        footer: null,
                                    })
                                    let cached_response = responsesMap.get(product.product_id);
                                    if (cached_response === undefined) {
                                        const request = {
                                            product_id: product.product_id,
                                        }
                                        const requestParams = {
                                            body: request
                                        }
                                        await API.post("BackendAuth", "signed_contents_url", requestParams).then((response: SignedContentResponse) => {
                                            setResponsesMap(new Map(responsesMap.set(product.product_id, response)))
                                            cached_response = response;
                                        })
                                    }
                                    m.update({
                                        title: product.product_name,
                                        content: <a href={cached_response?.response_parts[0].download_link}
                                                    download
                                                    target={"_blank"}
                                                    rel="noreferrer">
                                            <Button type={"primary"} block>{t("file-library-download-file")}</Button>
                                        </a>,
                                    })
                                }}>
                                    {t("file-library-generate-download-link")}
                                </Button>
                            ]}
                        >
                            <Card.Meta title={product.product_name + " - " + product.creator_name}
                                       description={product.product_description}/>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Content>
    );
}

export interface UserFileProduct {
    product_id: number,
    product_name: string,
    creator_name: string,
    product_description: string,
    image_url_link?: string
}
