import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {SubGacekRoutes} from './components/SubGacekRoutes';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import {AwsAuthenticatorProvider} from "./components/AwsAuthenticator";
import {useAwsAuthenticatorDataProvider} from "./components/StandardPage";
import '@aws-amplify/ui-react/styles.css';
import ReactGA from "react-ga4";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import i18n from "i18next";
import XHR from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {WebTranslations} from "./webTranslations";

let prodEnv: string | undefined = process.env.REACT_APP_PROD_ENV
ReactGA.initialize("G-C5JF5BPJEY", {testMode: prodEnv !== "true"});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


i18n
    .use(XHR)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init(WebTranslations);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AwsAuthenticatorProvider>
                <SubGacekRoutes userDataProvider={useAwsAuthenticatorDataProvider} testSetup={false}/>
            </AwsAuthenticatorProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
