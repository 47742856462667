import React, {useEffect, useState} from "react";

import {Amplify, API, Auth, I18n} from 'aws-amplify';
import {Authenticator, translations, useAuthenticator} from '@aws-amplify/ui-react';
import {AwsAuthenticatorConfig} from "./config/AwsAuthenticatorConfig";
import ReactGA from "react-ga4";
import FeedbackButton from "./FeedbackButton";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBRow,
    MDBTabsPane
} from "mdb-react-ui-kit";
import * as _ from "lodash";
import {AuthScreen} from "./auth/AuthScreen";
import {UserFileProduct, UserPanel} from "../user_panel/UserPanel";
import {Alert, Col, Flex, Layout, Row, Spin} from "antd";
import {useTranslation} from "react-i18next";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }

        interface IntrinsicElements {
            'stripe-buy-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

const config = AwsAuthenticatorConfig.fromEnvironment()
let apiEndpoint: string | undefined = process.env.REACT_APP_API_ENDPOINT
let api_object: any[];
if (apiEndpoint !== undefined) {
    api_object = [
        {
            name: "BackendAuth",
            endpoint: apiEndpoint,
            custom_header: async () => {
                return {
                    Authorization:
                        `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                }
            },
        }
    ]
} else {
    api_object = [];
}
Amplify.configure({
    Auth: {
        region: config.region,
        userPoolId: config.userPoolId,
        userPoolWebClientId: config.userPoolWebClientId,
    },
    API: {
        endpoints: api_object
    }
})
I18n.putVocabularies(translations);

type AwsAuthenticatorProviderProps = {
    children: React.ReactNode
}

// https://ui.docs.amplify.aws/react/guides/auth-protected
// https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#internationalization-i18n
export const AwsAuthenticatorProvider = ({children}: AwsAuthenticatorProviderProps) => {
    return (
        <Authenticator.Provider>
            {children}
        </Authenticator.Provider>
    )

}

interface SubModel {
    slug: string
    pretty_name: string
}

interface AvailableProductPresentation {
    product_image: string | null,
    product_name: string,
    product_description: string
}

interface AdditionalData {
    available_product_presentation: AvailableProductPresentation | undefined
}

interface CurrentProductDatum {
    product_id: number,
    product_name: string,
    creator_name: string,
    product_rank: number,
    additional_data: AdditionalData | undefined

}

export interface AuthenticatedUserData {
    subs: SubModel[]
    current_product_data: UserFileProduct[]
}

interface OwnedContentTabParams {
    justifyActive: string,
    userSubs: AuthenticatedUserData | undefined
}

export interface SignedContentResponse {
    response_parts: SingedResponsePart[]
}

interface SingedResponsePart {
    link_name: string,
    part_no: number,
    download_link: string,
}

const OwnedContentTab = ({justifyActive, userSubs}: OwnedContentTabParams) => {
    const [pdfDownloadModal, setPdfDownloadModal] = useState(new Map<number, boolean>());
    let EmptyResponse = {response_parts: []} as SignedContentResponse;
    const [downloadResponse, setDownloadResponse] = useState(EmptyResponse);
    const toggleShow = async (product_id: number) => {
        let newMap = new Map(pdfDownloadModal);
        let is_showing = pdfDownloadModal.get(product_id) ?? false;
        let updated_showing = !is_showing;
        newMap.set(product_id, updated_showing);
        if (!updated_showing) {
            setDownloadResponse(EmptyResponse);
        } else {
            const request = {
                product_id: product_id,
            }
            const amplifyInit = {
                body: request
            }
            API.post("BackendAuth", "signed_contents", amplifyInit).then((response: SignedContentResponse) => {
                setDownloadResponse(response);
            }).catch((error) => {
                let error_response: SignedContentResponse = {
                    response_parts: [{
                        link_name: "Wystąpił błąd podczas generowania linku. Spróbuj ponownie później.",
                        part_no: 0,
                        download_link: "#",
                    } as SingedResponsePart]
                }
            });
        }
        setPdfDownloadModal(newMap);
    }

    const shouldShowModalWithId = (product_id: number) => {
        return pdfDownloadModal.get(product_id) ?? false;
    }

    return (<>
        <MDBTabsPane show={justifyActive === 'owned-content'}>
            {_.chain(userSubs?.current_product_data)
                .flatMap((datum) => {
                    let result = [] as CurrentProductDatum[];
                    datum.product_name.split(" ").forEach((product) => {
                        let d = _.cloneDeep(datum);
                        d.product_name = product;
                        // result.push(d)
                    })
                    return result;
                })
                .groupBy('creator_name')
                .map((datum, key) => {
                    return (
                        <>
                            <MDBRow>
                                <p className={"text-center text-white display-6"}>{key}</p>
                            </MDBRow>
                            <MDBRow className='row-cols-1 row-cols-md-2 g-4'>
                                {datum.map((product) => {
                                    let description: string;
                                    if (product.product_name === 'SMS') {
                                        description = "Codzienna wiadomość SMS prosto na Twój numer telefonu."
                                    } else if (product.product_name === 'DISCORD') {
                                        description = "Dostęp do serwera Discord, gdzie obdywają się zamknięte Live'y, możesz porozmawiać z Twórcą " + product.creator_name + " oraz innymi subskrybentami."
                                    } else {
                                        let desc_from_data: string | undefined = product.additional_data?.available_product_presentation?.product_description
                                        if (desc_from_data !== undefined) {
                                            description = desc_from_data
                                        } else {
                                            description = ""
                                        }
                                    }
                                    let product_name: string;
                                    if (product.product_name === 'SMS' || product.product_name === 'DISCORD') {
                                        product_name = product.product_name
                                    } else {
                                        product_name = product.additional_data?.available_product_presentation?.product_name ?? product.product_name
                                    }

                                    return (<MDBCol>
                                        <MDBCard>
                                            <MDBCardBody>
                                                <MDBCardTitle>{product_name}</MDBCardTitle>
                                                <MDBCardText>
                                                    {product_name === 'DISCORD' && (
                                                        <div className={"alert alert-info"}> Jeżeli nie
                                                            posiadasz dostępu do Discorda, skontaktuj się ze
                                                            mną przez formularz (ikona chatu w górnym lewym
                                                            rogu) lub napisz bezpośrednio na
                                                            piotr.w.lewandowski@gmail.com.</div>)}
                                                    {description}

                                                    {product.product_name === 'VIRTUAL_PRODUCT_PDF' && (
                                                        <>
                                                            <hr/>
                                                            <MDBBtn block
                                                                    onClick={() => toggleShow(product.product_id)}>Wygeneruj
                                                                link do pobrania</MDBBtn>

                                                            <MDBModal show={shouldShowModalWithId(product.product_id)}
                                                                      staticBackdrop
                                                                      setShow={() => shouldShowModalWithId(product.product_id)}
                                                                      tabIndex='-1'>
                                                                <MDBModalDialog>
                                                                    <MDBModalContent>
                                                                        <MDBModalHeader>
                                                                            <MDBModalTitle>{product_name}</MDBModalTitle>
                                                                            <MDBBtn className='btn-close' color='none'
                                                                                    onClick={() => toggleShow(product.product_id)}></MDBBtn>
                                                                        </MDBModalHeader>
                                                                        <MDBModalBody>
                                                                            {downloadResponse.response_parts.length === 0 && (<>Ładowanie...</>)}
                                                                            {downloadResponse !== EmptyResponse && (<>
                                                                                    {
                                                                                        downloadResponse.response_parts.sort((a, b) => a.part_no - b.part_no).map((part) => {
                                                                                            return (
                                                                                                <MDBCard
                                                                                                    className={"mb-2"}>
                                                                                                    <MDBCardBody
                                                                                                        className={"text-white text-center"}>
                                                                                                        <MDBCardTitle>{part.link_name}</MDBCardTitle>
                                                                                                        <a
                                                                                                            href={part.download_link}
                                                                                                            download
                                                                                                            target={"_blank"}><MDBBtn
                                                                                                            block
                                                                                                            color={"success"}>Pobierz
                                                                                                            plik</MDBBtn></a>
                                                                                                    </MDBCardBody>
                                                                                                </MDBCard>
                                                                                            )
                                                                                        })}</>

                                                                            )}
                                                                        </MDBModalBody>

                                                                        <MDBModalFooter>
                                                                            <MDBBtn block color='secondary'
                                                                                    onClick={() => toggleShow(product.product_id)}>
                                                                                Zamknij
                                                                            </MDBBtn>
                                                                        </MDBModalFooter>
                                                                    </MDBModalContent>
                                                                </MDBModalDialog>
                                                            </MDBModal>
                                                        </>
                                                    )}
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>)
                                })}
                            </MDBRow>
                        </>
                    )
                }).value()}
        </MDBTabsPane>
    </>);
}

const {Header, Footer, Sider, Content} = Layout;

export const AwsAuthenticatorComponent = () => {
    useEffect(() => {
        ReactGA.send(
            {
                hitType: "pageview",
                page: "user_access",
            });
    }, []);
    const [justifyActive, setJustifyActive] = useState('owned-content');
    const handleJustifyClick = (value: string) => {
        if (value === justifyActive) {
            return;
        }

        setJustifyActive(value);
    };

    let lang = navigator.language
    I18n.setLanguage(lang);
    const {route} = useAuthenticator()
    let retrieveAndSetSubs = async () => {
        let retrieved_user_subs: AuthenticatedUserData = await API.get("BackendAuth", "subscription", {});
        setUserSubs(retrieved_user_subs);
    }
    const {t} = useTranslation()

    const [userSubs, setUserSubs] = useState(undefined as AuthenticatedUserData | undefined);
    useEffect(() => {
        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual
        if (route === "authenticated" && userSubs === undefined) {
            retrieveAndSetSubs();
        }
    }, [route, userSubs]);

    if (route !== "authenticated") {
        return (
            <>
                <Content>
                    <FeedbackButton/>
                    <Flex justify={"center"} align={"center"} gap={"middle"}>
                        <Col>
                            <Alert
                                message={t("user-login-how-to-setup-account")}
                                description={t("user-login-how-to-setup-description")}
                                type="info"
                                showIcon
                                closable
                                className={"mt-3"}
                            />
                            <AuthScreen/>
                        </Col>
                    </Flex>
                </Content>
            </>
        )
    } else if (route === "authenticated" && userSubs === undefined) {
        return (
            <Layout>
                <Content style={{height: "100vh"}}>
                    <FeedbackButton/>
                    <Flex justify="center" align="center" style={{ height: '100vh' }}>
                        <Spin size="large">
                        </Spin>
                    </Flex>
                </Content>
            </Layout>
        )

    } else {
        return (
            <>
                <FeedbackButton isRight={true}/>
                <UserPanel userData={userSubs as AuthenticatedUserData}/>
            </>
        )
    }
}

export enum PaymentSchedule {
    Yearly = "yearly",
    Monthly = "monthly"
}

export function handleSubscriptionOnClick(payment_link: string, schedule: PaymentSchedule) {
    return () => {
        ReactGA.event({
            action: schedule, category: "payment_click", label: payment_link, nonInteraction: false, value: 1

        })
        window.location.href = payment_link
    }

}